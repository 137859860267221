import React from 'react';
import Globalstyles from "../Styles/Globalstyles";
import Router from "./Router";
import Footer from "./Footer";


function App() {
  return (
   <>
        <Router/>
        <Footer/>
       <Globalstyles/>
   </>
  );
}

export default App;
